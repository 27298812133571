import { KEYMASTER_API, ROUTE_AUTH } from '../config'
import AuthService from './auth'

const CredService = {
  list: async refreshToken => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/creds/formats`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  create: async (cred, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    console.log('assigning card:', cred)
    const resp = await fetch(KEYMASTER_API + '/creds', {
      method: 'POST',
      body: JSON.stringify({ cred, accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  find: async (_name, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/creds/formats`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  remove: async (credentialId, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/creds/${credentialId}`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  get: async (cardNumber, facilityCode, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const resp = await fetch(`${KEYMASTER_API}/creds/${facilityCode}/${cardNumber}`, {
      method: 'POST',
      body: JSON.stringify({ accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  getUnique: async (cardNumber, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const resp = await fetch(`${KEYMASTER_API}/creds/uniqueCard/${cardNumber}`, {
      method: 'POST',
      body: JSON.stringify({ accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })
    return resp.json()
  },
  addUser: async (userId, credentialId, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    await fetch(`${KEYMASTER_API}/creds/user`, {
      method: 'POST',
      body: JSON.stringify({ userId, credentialId, accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })
  },
  findUserCreds: async (brivoUser, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const userId = brivoUser.id
    const url = new URL(`${KEYMASTER_API}/creds/user/${userId}`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  getUserCred: async (credentialId, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/creds/${credentialId}`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  createDigitalCred: async (userId, userEmail, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = `https://api.brivo.com/v1/api/users/${userId}/credentials/digital-invitations`
    const ref = { 'referenceId': userEmail }
    console.log('...', userId)
    await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'api-key': 'swa9yq94h9heec6czkycxvyc',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(ref)
    })
  }
}

export default CredService
