import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Box,
  Input,
  Select,
} from 'pcln-design-system'

import debounce from 'lodash.debounce'
import AuthService from '../services/auth'
import GroupService from '../services/group'

const StyledInput = styled(Input)`
  width: 214px;
  max-width: 214px;
`
const StyledSelect = styled(Select)`
  max-width: 214px;
`

function GroupSelect ({ onSelection, list: origList }) {
  const [list, setList] = useState({})
  const [selectedGroup, setSelectedGroup] = useState({})
  const [groupFilter, setGroupFilter] = useState('')

  useEffect(() => {
    setList(origList)
  }, [origList])

  useEffect(() => {
    onSelection(selectedGroup)
  }, [onSelection, selectedGroup])

  const searchGroup = async (group) => {
    const refreshToken = AuthService.getRefreshToken()
    const list = await GroupService.find(group, refreshToken)

    if (list.data && list.data.length) {
      setList(list)
      setSelectedGroup(list.data[0])
    }
  }

  const _findGroups = useCallback(debounce(text => {
    searchGroup(text)
  }, 1000), [])

  useEffect(() => {
    _findGroups(groupFilter)
  }, [groupFilter])

  const _handleChangeGroup = (e) => {
    const selectedGroup = list.data.find(g => g.id === +e.target.value)
    setSelectedGroup(selectedGroup)
  }

  return (
    <Box>
      <StyledInput
        id="groupSearch"
        name="groupSearch"
        style={list.count === 1 ? { borderRight: '10px solid rgba(45,222,38,0.8)' } : {}}
        value={groupFilter}
        onChange={e => setGroupFilter(e.target.value)} />
      <StyledSelect
        id="group"
        name="groupId"
        onChange={e => _handleChangeGroup(e)}
      >
        {
          list.data && list.data.map((g, i) => (
            <option key={i} value={g.id}>{g.name}</option>
          ))
        }
      </StyledSelect>
    </Box>
  )
}

GroupSelect.propTypes = {
  onSelection: PropTypes.func,
  list: PropTypes.object,
}

export default GroupSelect
