import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

import * as Sentry from '@sentry/browser'

Sentry.init({ dsn: 'https://4da6c47f8e5c4ecabd10a19687ec39a2@o187957.ingest.sentry.io/5377242' })

ReactDOM.render(<App />, document.getElementById('root'))
