import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Button,
  Card,
  Box,
  Heading,
  Input,
  Label,
  Flex,
} from 'pcln-design-system'

import AuthService from '../services/auth'

const CenteredBox = styled(Box)`
  margin-left: auto;
  margin-right: auto;
`

const ShortenedInput = styled(Input)`
  max-width: 240px;
`

function LoginForm ({ onError, onSuccess }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const _handleLogin = async () => {
    try {
      const auth = await AuthService.login(username, password)
      if (auth.error) {
        onError(auth.error_description)
      } else {
        await onSuccess()
      }
    } catch (e) {
      onError('Error')
    }
  }

  return (
    <CenteredBox>
      <Card m={3} p={3}>
        <Heading>Login</Heading>

        <Box width={1}>
          <Label mt={3}>Username</Label>
          <ShortenedInput
            id="username"
            value={username}
            name="username"
            onChange={e => setUsername(e.target.value)} />

          <Label mt={3}>Password</Label>
          <ShortenedInput
            id="password"
            value={password}
            type="password"
            name="password"
            onChange={e => setPassword(e.target.value)} />
        </Box>

        <Flex mt={3}>
          <Button ml="auto" onClick={_handleLogin}>Log In</Button>
        </Flex>
      </Card>
    </CenteredBox>
  )
}

LoginForm.propTypes = {
  onError: PropTypes.func,
  onSuccess: PropTypes.func
}

export default LoginForm
