import React from 'react'
import PropTypes from 'prop-types'
import logo from './logo.svg'

const Logo = ({ size, color }) => (
  <img
    src={logo}
    width={size || 50}
    style={{ fill: color || 'white' }}
    alt="logo" />
)

Logo.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

export default Logo
