import { KEYMASTER_API, ROUTE_AUTH } from '../config'

const UserService = {
  create: async (user, accessToken) => {
    const resp = await fetch(KEYMASTER_API + '/users', {
      method: 'POST',
      body: JSON.stringify({ user, accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  find: async (user, accessToken) => {
    const resp = await fetch(KEYMASTER_API + '/users/find', {
      method: 'POST',
      body: JSON.stringify({ user, accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  checkGroups: async (user, accessToken) => {
    const userId = user.id
    const resp = await fetch(KEYMASTER_API + '/users/groups', {
      method: 'POST',
      body: JSON.stringify({ userId, accessToken }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })
    return resp.json()
  },
  remove: async (user, accessToken) => {
    const userId = user.id
    const url = new URL(`${KEYMASTER_API}/users/${userId}`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })
    return resp.json()
  },
}

export default UserService
