import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Banner,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  Select as UnstyledSelect,
  Text
} from 'pcln-design-system'
import CSVReader from 'react-csv-reader'
import Plot from 'react-plotly.js'
import log from '../utils/log'

import LoginForm from './LoginForm'
import GroupSelect from './GroupSelect'
import CredSelect from './CredSelect'

import AuthService from '../services/auth'
import GroupService from '../services/group'
import CredService from '../services/creds'
import UserService from '../services/user'

import * as Sentry from '@sentry/browser'

const Select = styled(UnstyledSelect)`
  padding-top: 10px;
  padding-bottom: 10px;
`

function MainContainer () {
  const [refreshToken, setRefreshToken] = useState(AuthService.getRefreshToken())
  const [users, setUsers] = useState([])
  const [newUsers, setNewUsers] = useState([])
  const [failedUsers, setFailedUsers] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [credentialedUsers, setCredentialedUsers] = useState([])
  const [unassignedUsers, setUnassignedUsers] = useState([])
  const [creds, setCreds] = useState([])
  const [groups, setGroups] = useState({})
  const [credTypes, setCredTypes] = useState({})
  const [selectedGroup, setSelectedGroup] = useState({})
  const [selectedCred, setSelectedCred] = useState({
    id: 100,
    name: 'Standard 26 Bit',
    credentialFields: [
      {
        id: 1,
        name: 'card_number'
      },
      {
        id: 2,
        name: 'facility_code'
      }
    ]
  })
  const [selectedJob, setSelectedJob] = useState('IMPORT')
  const [error, setError] = useState('')

  const confirmLogin = () => {
    const newToken = AuthService.getRefreshToken()
    if (!newToken) throw new Error('auth service failed')
    setRefreshToken(newToken)
    setCreds([])
  }

  useEffect(() => {
    if (refreshToken) {
      GroupService.list(refreshToken)
        .then(setGroups)
        .catch(setError("Unable to load groups"))
      CredService.list(refreshToken)
        .then(setCredTypes)
        .catch(setError("Could not load credential types"))
    }
  }, [refreshToken])

  const _handleUploadUsers = dataWithHeader => {
    const [, ...data] = dataWithHeader;

    const header = dataWithHeader[0]
    if (header[0] === '' && header[1] === '') {
      log('X', header) // bad data
    } else {
      if (header[0] !== 'FirstName' ||
          header[1] !== 'LastName' ||
          header[2] !== 'Email' ||
          header[3] !== 'Group' ||
          header[4] !== 'FacilityId' ||
          header[5] !== 'CardNum' ||
          header[6] !== 'CardType') {
          Sentry.captureException(new Error('Invalid CSV Format. Please check your source.'), {
            tags: {
              header: header.join(','),
            },
          })
          setError('Invalid CSV Format. Please check your source.')
      }
    }

    const users = data.map(cred => {
      const firstInitial = cred[0].substr(0, 1).toLowerCase().trim()
      const lastName = cred[1].toLowerCase().trim()
      return {
        firstName: cred[0].trim() || '',
        lastName: cred[1].trim() || '',
        email: cred[2] || `${firstInitial}${lastName}@noemail.com`,
        group: cred[3] || '',
        facilityId: cred[4] || '',
        cardNumber: cred[5] || '',
        cardType: cred[6] || ''
      }
    })

    setUsers(users)
  }

  const checkUserGroups = async (user, accessToken) => {
    const foundGroups = await UserService.checkGroups(user, accessToken)
    let idx = null
    if (foundGroups.data) {
      idx = foundGroups.data.filter(g => g.id === selectedGroup.id)
    }
    if (idx !== null && idx.length) {
      log('! user belongs to :', idx[0].name)
    } else {
      log(`${user.firstName} ${user.lastName} is not found!`)
      const groupResult = await GroupService.addUser(user.id, selectedGroup.id, accessToken)
      if (groupResult.message && groupResult.code === 404) {
        // User may exist: Search individually
        log({ error: groupResult.message })
      } else {
        log('Success User Group')
        setAssignedUsers([...assignedUsers, groupResult])
      }
    }
  }

  const bootUserGroups = async (user, accessToken) => {
    const foundGroups = await UserService.checkGroups(user, accessToken)
    let idx = null
    if (foundGroups.data) {
      idx = foundGroups.data.filter(g => g.id === selectedGroup.id)
    }
    if (idx !== null && idx.length) {
      log('! user belongs to :', idx[0].name)
      const groupResult = await GroupService.removeUser(user.id, selectedGroup.id, accessToken)
      if (groupResult.message && groupResult.code === 404) {
        // User may exist: Search individually
        log({ error: groupResult.message })
      } else {
        log('Success User Group Removed', groupResult)
        setUnassignedUsers([...unassignedUsers, groupResult])
      }
    } else {
      log(`${user.firstName} ${user.lastName} is not found!`)
    }
  }

  const selectGroup = (group) => {
    setSelectedGroup(group)
  }

  const checkCred = async (user, brivoUser, accessToken) => {
    let oldCred = {}
    if (user.facilityId) {
      oldCred = await CredService.get(user.cardNumber, user.facilityId, accessToken)
    } else {
      oldCred = await CredService.getUnique(user.cardNumber, accessToken)
    }

    if (oldCred.count > 0) {
      log('+ cred is in', oldCred)
      await CredService.addUser(brivoUser.id, oldCred.data[0].id, accessToken)
      setCredentialedUsers([...credentialedUsers, user])
    } else {
      log(oldCred.count, 'cred is free', oldCred, user.cardNumber, user.facilityId)
      createCred(user, selectedCred, brivoUser, accessToken)
      setCreds([...creds, oldCred])
    }
  }

  const createCred = async (user, selectedCred, brivoUser, accessToken) => {
    console.log('>', selectedCred)
    const cred = {
      credentialFormat: {
        id: selectedCred.id,
        name: selectedCred.name
      },
      referenceId: user.cardNumber,
      fieldValues: [
        {
          id: selectedCred.credentialFields[0].id,
          value: user.cardNumber,
          name: selectedCred.credentialFields[0].name
        }
      ]
    }

    // Only add if a facilityId is added.
    // Extends unknown credential types
    if (selectedCred.credentialFields.length > 1) {
      cred.fieldValues.push(
        {
          id: selectedCred.credentialFields[1].id,
          value: user.facilityId,
          name: selectedCred.credentialFields[1].name
        }
      )
    }

    const newCred = await CredService.create(cred, accessToken)
    if (newCred.code === 400) {
      log('duplicate? ', user.cardNumber, user.facilityId, newCred.message)
      const userCred = await CredService.findUserCreds(brivoUser, accessToken)
      const dupedCred = await CredService.get(user.cardNumber, user.facilityId, accessToken)
      log('userCreds:', userCred)
      log('> dupedCred:', dupedCred)
      setUnassignedUsers([...unassignedUsers, userCred])
    } else if (newCred.type === 'invalid-json') {
      log('failed cred >', newCred.message)
      setFailedUsers([...failedUsers, user])
    } else {
      log('credCreated:', newCred)
      const credUser = CredService.addUser(brivoUser.id, newCred, accessToken)
      setCredentialedUsers([...credentialedUsers, credUser])
    }

    await CredService.createDigitalCred(brivoUser.id, user.Email, accessToken)
      .then(digitalCred => {
        log(digitalCred)
      })
      .catch(err => {
        console.warn(err)
      })
  }

  const selectCred = (cred) => {
    setSelectedCred(cred)
  }

  const createCredentials = async (users) => {
    for (const u in users) {
      let token = await AuthService.getRefreshToken()
      await checkUser(users[u], token)
    }
  }

  const removeCredentials = async (users) => {
    for (const u in users) {
      let token = await AuthService.getRefreshToken()
      await bootUser(users[u], token)
    }
  }

  const createUser = async (user, refreshToken) => {
    const userResult = await UserService.create(user, refreshToken)
    if (userResult.message && userResult.code === 400) {
      setError(userResult.message)
      // User may exist: Search individually
    } else {
      setNewUsers([...newUsers, userResult])
      log('u:', userResult)
      if (userResult.type === 'invalid-json') {
        setFailedUsers([...failedUsers, user])
      } else if (userResult.error === 'invalid_token') {
        setFailedUsers([...failedUsers, user])
      }

      // assign user to group
      const groupResult = await GroupService.addUser(userResult.id, selectedGroup.id, refreshToken)
      if (groupResult.message && groupResult.code === 404) {
        // User may exist: Search individually
        // log({ error: groupResult.message })
      } else {
        setAssignedUsers([...assignedUsers, groupResult])
      }

      checkCred(user, userResult, refreshToken)
    }
  }

  const removeUser = async (user, foundUser, refreshToken) => {
    await UserService.remove(foundUser, refreshToken)
  }

  const checkUser = async (user, refreshToken) => {
    const foundUsers = await UserService.find(user, refreshToken)

    if (foundUsers.count) {
      // select first user
      const foundUser = foundUsers.data[0]
      log(foundUser)
      await checkUserGroups(foundUser, refreshToken)
      await checkCred(user, foundUser, refreshToken)
    } else {
      await createUser(user, refreshToken)
    }
  }

  const bootUser = async (user, refreshToken) => {
    const foundUsers = await UserService.find(user, refreshToken)

    if (foundUsers.count) {
      // select first user
      const foundUser = foundUsers.data[0] // Danger!
      await bootUserGroups(foundUser, refreshToken)
      await removeUserCreds(user, foundUser, refreshToken)
      await removeUser(user, foundUser, refreshToken)
    } else {
      await removeUser(user, refreshToken)
    }
  }

  const removeUserCreds = async (user, brivoUser, refreshToken) => {
    log('checking creds for ', brivoUser.id)
    const creds = await CredService.findUserCreds(brivoUser, refreshToken)
    if (creds.count) {
      log('found ', creds.count, ' creds')
      if (creds.data[0].fieldValues[1] &&
        creds.data[0].fieldValues[1].name === 'facility_code' &&
        creds.data[0].fieldValues[1].value === user.facilityId) {
        const removedCred = await CredService.remove(creds.data[0].id, refreshToken)
        log('cred removed!', removedCred)
      }
    } else {
      log('>', creds)
      return false
    }
  }

  const _cancelJob = () => {
    setUsers([])
  }

  const _submitJob = async () => {
    switch (selectedJob) {
      case 'IMPORT':
        await createCredentials(users,
          selectedGroup,
          selectedCred)
        break
      case 'REMOVE_CREDS_USERS':
        await removeCredentials(users,
          selectedGroup,
          selectedCred)
        break
      default:
        log('No Job Type Defined')
        break
    }
  }

  const _handleChangeJob = e => {
    setSelectedJob(e.target.value)
  }

  const yValue = [
    users.length,
    newUsers.length,
    assignedUsers.length,
    credentialedUsers.length,
    unassignedUsers.length,
    failedUsers.length
  ]
  return (
    <Container>
      {error && (
        <Banner
          px={3} py={2}
          showIcon={false}
          bg="red"
        >
          {error}
        </Banner>
      )}

      {!refreshToken && (
        <LoginForm
          onSuccess={async () => {
            setError(null)
            await confirmLogin()
          }}
          onError={(desc) => { setError(`Invalid Login: ${desc}`) }} />
      )}
      {refreshToken && (
        <>
          <Box py={3}>
            <Box>
              <Heading>Import Google Sheet</Heading>
              <Box
                bg="lightGray"
                p={3} my={3}
              >
                <CSVReader
                  cssClass="csv-reader-input"
                  label="Select CSV with User and Card Info"
                  onFileLoaded={_handleUploadUsers}
                  onError={setError} />
              </Box>

              [ <Link href="https://docs.google.com/spreadsheets/d/18V-2Fe4o2J5jcGlrJ8Zx3UoudNSMYaHwEro2uVfEspI/edit#gid=0" target="_blank">View Template</Link> ]
            </Box>
          </Box>
          <Divider />
          <Box>
            <Box>
              <Plot
                data={[
                  {
                    x: ['Loaded', 'New', 'Assigned', 'Credentialed', 'Unassigned', 'Failed'],
                    y: yValue,
                    marker: {
                      color: [
                        'rgba(204,204,204,0.5)',
                        'rgba(45,222,38,0.4)',
                        'rgba(45,222,38,0.6)',
                        'rgba(45,222,38,0.8)',
                        'rgba(222,45,38,0.5)',
                        'rgba(222,45,38,0.8)'
                      ]
                    },
                    text: yValue.map(String),
                    textposition: 'auto',
                    type: 'bar'
                  },
                ]}
                layout={{
                  width: 600,
                  height: 300,
                  title: 'Loaded Users',
                  xaxis: {
                    tickangle: -45
                  }
                }} />
            </Box>
          </Box>
          <Divider />
          <Box py={3}>
            <Box width={[1]}>
              <Heading>Select Groups and Card Type</Heading>
            </Box>
            <Flex my={2}>
              <Box width={[1, 1 / 2]} p={2}>
                <GroupSelect list={groups} onSelection={e => selectGroup(e)} />
              </Box>
              <Box width={[1, 1 / 2]} p={2}>
                <CredSelect list={credTypes} onSelection={e => selectCred(e)} />
              </Box>
            </Flex>
          </Box>
          <Divider />
          {selectedGroup && selectedCred && users.length > 0 &&
            (
              <Box py={3}>
                <Divider />
              </Box>
            )}
          {selectedGroup && selectedCred && users.length > 0 &&
          (
            <Box py={3}>
              <Box width={1}>
                <Heading>Confirm</Heading>
                <Text>
                  {selectedJob.toString()} &nbsp;
                  <strong>
                    {users.length} employees
                  </strong>&nbsp;in&nbsp;
                  <strong>
                    {selectedGroup.name}
                  </strong>
                      &nbsp;with&nbsp;
                  <strong>
                    {selectedCred.name}
                  </strong>
                </Text>
              </Box>
              <Box>
                <Flex my={2}>
                  <Box width={[1 / 4]} p={2}>
                    <Button variation="outline" width={1} onClick={() => { _cancelJob() }}>Cancel</Button>
                  </Box>
                  <Box width={[1 / 2]} p={2}>
                    <Select
                      id="job" name="selectedJob"
                      onChange={e => _handleChangeJob(e)}
                    >
                      <option value="IMPORT"> Import Users/Creds to Group</option>
                      <option disabled value="REMOVE_USERS">Remove Users from Group</option>
                      <option disabled value="REMOVE_CREDS">Remove Creds from Users in Group</option>
                      <option value="REMOVE_CREDS_USERS">Remove Creds and Users in Group</option>
                    </Select>
                  </Box>
                  <Box width={[1 / 4]} p={2}>
                    <Button width={1} onClick={() => { _submitJob() }}>Submit</Button>
                  </Box>
                </Flex>
              </Box>
            </Box>
          )}
        </>)}
    </Container>
  )
}

export default MainContainer
