import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Logo from './Logo'

const Nav = styled.header`
  align-items: center;
  background-color: ${props => props.theme.appColor};
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.40);
  color: white;
  display: flex;
  height: 60px;
  padding: 0 1rem;
`

const Title = styled.div`
  color: white;
  font-size: 1.2rem;
  padding-left: 1em;
  font-weight: 500;
  position: relative;
  bottom: 0.19rem;
`

const Header = ({ title }) => (
  <Nav>
    <a href="/"><Logo size={110} /></a>
    <Title>
      {title}
    </Title>
  </Nav>
)

Header.propTypes = {
  title: PropTypes.string
}

export default Header
