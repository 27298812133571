import { KEYMASTER_API, ROUTE_AUTH } from '../config'
import AuthService from './auth'

const GroupService = {
  list: async refreshToken => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/groups`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    const resp = await fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })

    return resp.json()
  },
  find: async (name, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/groups/${name}`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }
    const resp = await fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ROUTE_AUTH
      }
    })
    return resp.json()
  },
  addUser: async (userId, groupId, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    console.log('user-group', userId, groupId)
    if (userId && groupId) {
      const resp = await fetch(`${KEYMASTER_API}/groups/user`, {
        method: 'POST',
        body: JSON.stringify({ userId, groupId, accessToken }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ROUTE_AUTH
        }
      })
      return resp.json()
    }
  },
  removeUser: async (userId, groupId, refreshToken) => {
    const accessToken = await AuthService.refresh(refreshToken)
    const url = new URL(`${KEYMASTER_API}/groups/${groupId}/user/${userId}`)
    const params = { access_token: accessToken }
    for (const key in params) {
      url.searchParams.append(key, params[key])
    }
    if (userId && groupId) {
      const resp = fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ROUTE_AUTH
        }
      })
      return resp.json()
    }
  }
}

export default GroupService
