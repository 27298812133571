import React, { Component } from 'react'
// import Intercom from 'react-intercom'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Container } from 'pcln-design-system'
import theme from './theme'

import Header from './components/Header'
import MainContainer from './components/MainContainer'

createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    overflow: hidden;
    font-family: 'Montserrat', 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
const AppContainer = styled(Container)`
  width: 50%;
`

theme.colors.primary = theme.colors.blue
theme.appColor = theme.colors.primary

class App extends Component {
  render () {
    console.log('theme:', theme)
    return (
      <ThemeProvider theme={theme}>
        <>
          <Header title="Keymaster" />
          <AppContainer>
            <MainContainer />
            {/* <Intercom appID="hrl1agbj" /> */}
          </AppContainer>
        </>
      </ThemeProvider>
    )
  }
}

export default App
