import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Input, Select } from 'pcln-design-system'

import debounce from 'lodash.debounce'
import AuthService from '../services/auth'
import CredService from '../services/creds'

const StyledInput = styled(Input)`
  width: 214px;
  max-width: 214px;
`
const StyledSelect = styled(Select)`
  max-width: 214px;
`

function CredSelect ({ onSelection, list: origList }) {
  const [list, setList] = useState({})
  const [selectedCred, setSelectedCred] = useState({ id: 100, name: 'Standard 26 Bit' })
  const [credFilter, setCredFilter] = useState('')

  useEffect(() => {
    setList(origList)
  }, [origList])

  useEffect(() => {
    onSelection(selectedCred)
  }, [onSelection, selectedCred])

  useEffect(() => {
    _findCreds(credFilter)
  }, [credFilter])

  const searchCreds = async (cred) => {
    const refreshToken = await AuthService.getRefreshToken()
    const list = await CredService.find(cred, refreshToken)

    if (list === undefined || list.error === 'invalid_token') {
      this._refreshToken()
    }
    if (list.data && list.data.length) {
      setList(list)
      setSelectedCred(list.data[0])
    }
  }

  const _findCreds = useCallback(debounce(text => {
    searchCreds(text)
  }, 1000), [])

  const _handleChangeCred = (e) => {
    const selectedCred = list.data.find(c => c.id === +e.target.value)
    console.log(selectedCred)
    setSelectedCred(selectedCred)
  }

  return (
    <Box>
      <StyledInput
        id="credSearch"
        name="credSearch"
        style={list.count === 1 ? { borderRight: '10px solid rgba(45,222,38,0.8)' } : {}}
        value={credFilter}
        onChange={e => setCredFilter(e.target.value)} />
      <StyledSelect
        id="credType"
        name="credId"
        onChange={e => _handleChangeCred(e)}
      >
        {
          list.data
            ? list.data.map((cT, i) => {
              return (
                <option key={i} value={cT.id}>{cT.name}</option>
              )
            })
            : null
        }
      </StyledSelect>
    </Box>
  )
}

CredSelect.propTypes = {
  onSelection: PropTypes.func,
  list: PropTypes.object
}

export default CredSelect
