import { KEYMASTER_API, ROUTE_AUTH } from '../config'
const storage = window.sessionStorage

let refreshToken = ''

const AuthService = {
  login: async (username, password) => {
    const resp = await fetch(KEYMASTER_API + '/auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: ROUTE_AUTH
      },
      body: JSON.stringify({
        username,
        password
      })
    })

    const res = await resp.json()

    if (res.error_description) {
      console.warn('X', res.error_description, username, password)
    } else {
      storage.setItem('brivo_access_token', res.access_token)
      storage.setItem('brivo_refresh_token', res.refresh_token)
      return res
    }
  },
  refresh: async (refreshToken) => {
    const resp = await fetch(KEYMASTER_API + '/auth/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ROUTE_AUTH
      },
      body: JSON.stringify({
        refreshToken
      })
    })

    const res = await resp.json()
    storage.setItem('brivo_access_token', res.access_token)
    AuthService.setRefreshToken(res.refresh_token)
    return res.access_token
  },
  getRefreshToken: () => {
    refreshToken = refreshToken || storage.getItem('brivo_refresh_token')
    return refreshToken
  },
  setRefreshToken: (newToken) => {
    refreshToken = newToken
    storage.setItem('brivo_refresh_token', refreshToken)
  }
}

export default AuthService
